import React from 'react';
// import logo from './logo.svg';
import './App.css';
// Icons
import { ReactComponent as Facebook } from './Assets/icons/facebook-icon.svg';
import { ReactComponent as Instagram } from './Assets/icons/instagram-icon.svg';
import { ReactComponent as Linkedin } from './Assets/icons/linkedin-icon.svg';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
       
      </header> */}
      <main className="main">
        <div className="container">
          <div className="">
            <h1 className="title">Algo nuevo esta llegando</h1>
            <span className="subtitle">¡Muy pronto podrás conocerlo!</span>
          </div>
          <div className="box">
            <span className='almost'>¡Ya casi iniciamos! <span>Entérate tú primero</span></span>
            
            <span>Seguinos </span>
            <div className="socials">
              <a href={`https://www.instagram.com/ewins_ar/`}>
                <Instagram />
              </a>
              <a
                href={`https://ar.linkedin.com/company/neocomplexx`}
              >
                <Linkedin />
              </a>
              <a href={`https://www.facebook.com/neocomplexx`}>
                <Facebook />
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
